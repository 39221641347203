/**
 *
 * @param {number} percentNum - accept value 0 until 100
 * eg:
 * import hexOpacity from "./hexOpacity";
 *
 * ...
 * ...
 *  color: ${color.primary + hexOpacity(80)}
 * ...
 *
 */

const percentToHex = (percentNum = 0) =>
  Math.round((percentNum / 100) * 255)
    .toString(16)
    .padStart(2, "0");

export default percentToHex;
