import produce from 'immer';
import create, { StateCreator } from 'zustand';
import type {
  LivePriceActions,
  LivePriceState,
  LivePriceStore,
} from './liveprice.store.types';

const livePriceState: LivePriceState = {
  symbols: {},
};

const livePriceActions: StateCreator<LivePriceStore, [], [], LivePriceActions> =
  (set) => ({
    setLivePriceSymbols: (key, symbols) => {
      set((state) =>
        produce(state, (draft) => {
          draft.symbols[key] = symbols.filter((v, i, a) => a.indexOf(v) === i);
        }),
      );
    },
  });

export const useLivePriceStore = create<LivePriceStore>(
  (set, get, store, storeMutation) => ({
    ...livePriceState,
    ...livePriceActions(set, get, store, storeMutation),
  }),
);
