import { Modal } from "antd";
import styled, { css } from "styled-components";
import color from "../_style/color";
import device from "../_style/device";

const basicModal = css`
  .ant-modal-header {
    border-bottom: 0;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 0;
    text-align: unset;
  }

  .ant-modal-footer button + button {
    margin: 0;
  }
`;

const transparentModal = css`
  .ant-modal-content {
    background: unset;
    box-shadow: unset;
  }

  .ant-modal-close {
    top: 20px;
    right: 30px;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-header {
    background: unset;
    border: 0;
    padding: 0;
  }
`;

const fullscreenModal = css`
  width: unset !important;
  height: 100vh;
  top: unset;
  background-color: ${color.light};
  max-width: unset;
  margin: unset;

  .ant-modal-header {
    border: unset;
    padding: 40px 50px 30px;
  }

  .ant-modal-close {
    top: 3%;
  }

  .ant-modal-content {
    box-shadow: unset;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  .ant-modal-body {
    padding: 0;
    height: 90%;
    overflow-y: scroll;
  }

  @media ${device.tablet} {
    .ant-modal-content {
      width: 660px;
    }
  }
`;

const modalTypes = {
  basic: basicModal,
  transparent: transparentModal,
  fullscreen: fullscreenModal,
};

export default styled(Modal).attrs((props) => ({
  footer: props.footer || false,
  type: props.type || "basic",
}))`
  ${({ type }) => modalTypes[type]}
`;
