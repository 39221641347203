import {
  FC,
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import produce from 'immer';
import auth from 'utils/auth';
import wsApi from 'lib/api/ws';
import debounce from 'lodash.debounce';
import {
  WebsocketRequest,
  WebsocketWrapMessageChannel,
} from '@stockbitgroup/protos/securities/transactional/datafeed/v1/websocket_pb';
import { useRouter } from 'next/router';
import { useDatafeedStore } from './store';
import { useLivePriceStore } from './UseLivePrice/liveprice.store';
import { useOrderbookStore } from './UseOrderbook/orderbook.store';
import { useMarketMoverStore } from './UseMarketMover/marketmover.store';

export interface DatafeedProviderProps {
  heartbeatDuration?: number;
  isLoggedIn: boolean;
}

export type DatafeedAction<T = void | Promise<void>> = (
  message: DatafeedMessageChannel,
) => T;

export type SetDatafeedAction = (
  channel: keyof DatafeedMessageChannel,
  key: string,
  callbackFn: DatafeedAction,
) => void;

export type RemoveDatafeedAction = (
  channel: keyof DatafeedMessageChannel,
  key: string,
) => void;

export interface DatafeedContextValue {
  socket: WebSocket | undefined;
  isSocketConnected: boolean;
  setAction: SetDatafeedAction;
  removeAction: RemoveDatafeedAction;
  isAuthorized: boolean;
  error: DatafeedError;
  setChannel: (channel: Partial<DatafeedChannel>) => void;
}

type ActionCollection = Record<string, DatafeedAction>;

type ActionRef = Partial<
  Record<keyof DatafeedMessageChannel, ActionCollection>
>;

export const DatafeedContext = createContext<DatafeedContextValue>(undefined);

const MAX_RECONNECT_COUNTER = 5;

const routesBlacklist = ['/logout'];

export const DatafeedProvider: FC<DatafeedProviderProps> = ({
  heartbeatDuration = 10000,
  isLoggedIn,
  children,
}) => {
  const router = useRouter();
  const socket = useRef<WebSocket>();
  const actionsRef = useRef<ActionRef>({});
  const reconnectCounter = useRef(1);
  const isLoggedInRef = useRef(false);
  const authRetries = useRef(0);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const [isSocketConnected, setConnectionStatus] = useState<boolean>(false);
  const isBlacklisted = useMemo(
    () => routesBlacklist.includes(router.pathname),
    [router.pathname],
  );

  const { setChannel, setError, error, channel } = useDatafeedStore(
    (state) => ({
      setChannel: state.setChannel,
      setError: state.setError,
      error: state.error,
      channel: state.channel,
    }),
  );

  const livePriceStoreSymbols = useLivePriceStore((s) => s.symbols);
  const orderbookStoreSymbols = useOrderbookStore((s) => s.symbols);
  const marketMoverList = useMarketMoverStore((s) => s.request);

  const initSocket = () => {
    setAuthToken(undefined);

    socket.current = new WebSocket(process.env.NEXT_PUBLIC_DATAFEED_SOCKET);
    socket.current.binaryType = 'arraybuffer';

    // eslint-disable-next-line no-use-before-define
    socket.current.addEventListener('open', handleAuth, { once: true });
    // eslint-disable-next-line no-use-before-define
    socket.current.addEventListener('close', handleReconnection, {
      once: true,
    });

    window.datafeedSocket = socket.current;
  };

  const resetSocket = () => {
    socket.current.close();
    socket.current = undefined;
    window.datafeedSocket = undefined;
    setAuthToken(undefined);
  };

  const sendMessageChannel = useCallback(
    async (authToken: string, messageChannel: Partial<DatafeedChannel>) => {
      if (!socket.current || socket.current?.readyState !== WebSocket.OPEN) {
        console.warn('Failed to send Channel, Socket not ready');
        return;
      }

      const { id: userId } = auth.getUserAccess() as any;
      const buffer = new WebsocketRequest({
        channel: messageChannel,
        key: authToken,
        userId,
      }).toBinary();
      socket.current?.send(buffer);
    },
    [],
  );

  const sendPingHeartbeat = async () => {
    if (!socket.current || socket.current?.readyState !== WebSocket.OPEN) {
      console.warn('Failed to send Ping, Socket not ready');
      return;
    }
    const buffer = new WebsocketRequest({
      ping: { message: 'ping' },
    }).toBinary();
    socket.current?.send(buffer);
  };

  const combinedChannel: Partial<DatafeedChannel> = useMemo(() => {
    const flattenedLivePriceStoreSymbols = Object.values(
      livePriceStoreSymbols,
    ).flat();
    const flattenedOrderbookStoreSymbols = Object.values(
      orderbookStoreSymbols,
    ).flat();

    return {
      ...channel,
      liveprice: Array.from(
        new Set([
          ...(channel.liveprice ?? []),
          ...flattenedLivePriceStoreSymbols,
        ]),
      ),
      orderBook: Array.from(
        new Set([
          ...(channel.orderBook ?? []),
          ...flattenedOrderbookStoreSymbols,
        ]),
      ),
      marketMover: Object.values(marketMoverList),
      // isHotlist: true,
    };
  }, [channel, livePriceStoreSymbols, orderbookStoreSymbols, marketMoverList]);

  const handleSendChannel: typeof sendMessageChannel = useCallback(
    debounce(sendMessageChannel, 100),
    [],
  );

  const setAction: SetDatafeedAction = (channelKey, actionKey, callbackFn) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      if (!draft[channelKey]) draft[channelKey] = {};
      draft[channelKey][actionKey] = callbackFn;
    });
  };

  const removeAction: RemoveDatafeedAction = (channelKey, actionKey) => {
    actionsRef.current = produce(actionsRef.current, (draft) => {
      if (draft?.[channelKey]?.[actionKey]) delete draft[channelKey][actionKey];
    });
  };

  const handleUnauthorized = (message: DatafeedMessageChannel) => {
    if (authRetries.current > 3) {
      setError(message.error);
      return;
    }

    authRetries.current += 1;
    setAuthToken(undefined);

    // eslint-disable-next-line no-use-before-define
    handleAuth();
  };

  const handleErrorMessage = async (message: DatafeedMessageChannel) => {
    // Consider moving the error handling code to a separate 'utils' module
    // if the amount of error handling logic is increasing
    if (message?.error?.code === 401) handleUnauthorized(message);
  };

  const handleMessage = async (message: MessageEvent<ArrayBuffer>) => {
    const dataRaw = WebsocketWrapMessageChannel.fromBinary(
      new Uint8Array(message.data),
    );
    const { messageChannel } = dataRaw;
    const { case: messageCase, value } = messageChannel;
    // Due to impacted types will impact large section such as Running Trade and Orderbook
    // current decodedMessage casted as any, if not, we need to re-define all types to use
    // related types at @stockbitgroup/protos module which will make a huge changes
    // so currently marking this as TODO
    // TODO: Change any and all related module types to to use types from @stockbitgroup/protos
    const decodedMessage: any = { [messageCase]: value };
    if (decodedMessage?.error) handleErrorMessage(decodedMessage);

    Object.keys(decodedMessage).forEach(
      (messageChannelKey: keyof DatafeedMessageChannel) => {
        Object.values(actionsRef.current[messageChannelKey] || {}).forEach(
          (callbackFn) => {
            callbackFn(decodedMessage);
          },
        );
      },
    );
  };

  // handleHeartbeatMessage will only called when ping message available
  // filtered on handleMessage
  const handleHeartbeatMessage = () => {
    setTimeout(() => sendPingHeartbeat(), heartbeatDuration);
  };

  const handleAuth = async () => {
    const { id: userId } = auth.getUserAccess() as any;
    const { data } = await wsApi.getWSTradingKey();
    const { data: bodyData } = data;
    const { key } = bodyData;
    const buffer = new WebsocketRequest({ key, userId }).toBinary();

    socket.current?.send(buffer);
    setAuthToken(key);

    setAction('ping', 'pingResponse', handleHeartbeatMessage);
    sendPingHeartbeat();
    socket.current?.addEventListener('message', handleMessage);

    setConnectionStatus(true);
  };

  const handleReconnection = () => {
    if (!isLoggedInRef.current) return;
    if (reconnectCounter.current > MAX_RECONNECT_COUNTER) {
      setError({ code: 503, message: 'Maximum reconnection' });
      return;
    }

    reconnectCounter.current += 1;
    setConnectionStatus(false);
    initSocket();
  };

  useEffect(() => {
    if (isBlacklisted) return;

    isLoggedInRef.current = isLoggedIn;
    if (!socket.current && isLoggedIn) initSocket();
    else if (socket.current && !isLoggedIn) resetSocket();
  }, [isLoggedIn, isBlacklisted]);

  useEffect(() => {
    if (authToken) handleSendChannel(authToken, combinedChannel);
  }, [authToken, handleSendChannel, combinedChannel]);

  return (
    <DatafeedContext.Provider
      value={{
        socket: socket.current,
        isSocketConnected,
        error,
        setChannel,
        setAction,
        removeAction,
        isAuthorized: !!authToken,
      }}
    >
      {children}
    </DatafeedContext.Provider>
  );
};
