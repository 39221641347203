import useSecuritiesCredentialStore from 'global/Securities/stores/useSecuritiesCredentialStore';
import { ACCOUNT_TYPE } from 'global/TradingModal/RealTradingModal/types';

const useTradingAccount = () => {
  const tradingType = useSecuritiesCredentialStore((s) => s.accountType);
  const isRealTrading = tradingType === ACCOUNT_TYPE.REAL;
  const isVirtualTrading = tradingType === ACCOUNT_TYPE.VIRTUAL;

  return { tradingType, isRealTrading, isVirtualTrading };
};

export default useTradingAccount;
