import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';

const request = requestConfig();

const CALENDAR_URL = `${API_EXODUS_URL}/corpaction`;

const getCalendarTodaySummary = (symbol?: string) =>
  request.get(CALENDAR_URL, { params: { symbol } });
/**
 * @summary - Get Calendar Data based on Type
 * @param {string} calendarType - type of calendar. type: economic | dividend | stocksplit
 * | reversesplit | rightissue | warrant | bonus | tenderoffer | rups | pubex | ipo
 * @return {Promise} Axios promise
 */
const getCalendarFor = (calendarType: string, symbol?: string) =>
  request.get(`${CALENDAR_URL}/${calendarType}`, { params: { symbol } });

export { getCalendarTodaySummary, getCalendarFor };
