interface IField {
  name?: string;
  value?: string;
  key?: string;
}

/**
 * Upload image handler
 * @param file - File to be uploaded
 * @param url - AWS Bucket URL
 * @param awsFields - Additional aws fields from `getPolicy` API
 * @param - type upload chat|stream
 * @returns {Promise<*>} fetch function
 */
const uploadHandler = (file: any, url: string, awsFields: Array<any>, type: 'chat' | 'stream') => new Promise((resolve, reject) => {
  const fd = new FormData();

  let formerFile = null;
  // eslint-disable-next-line no-underscore-dangle
  if (file._file) formerFile = file._file;

  // eslint-disable-next-line array-callback-return
  if (type === 'chat') {
    awsFields.map((field: IField) => fd.append(field.name, field.value));
  } else if (type === 'stream') {
    awsFields.map((field: IField) => fd.append(field.key, field.value));
  }

  if (!formerFile) {
    fd.append('file', file);
  } else {
    fd.append('file', formerFile);
  }

  return fetch(url, {
    method: 'POST',
    cache: 'default',
    body: fd,
    mode: 'no-cors',
  })
    .then((response) => {
      resolve(response.ok);
    })
    .catch((error) => {
      reject(new Error(error));
    });
});

export default uploadHandler;
