import produce from 'immer';
import create, { StateCreator } from 'zustand';

export interface State {
  channel: Partial<DatafeedChannel>;
  error?: DatafeedError;
}

export interface Actions {
  setChannel: (value: Partial<DatafeedChannel>) => void;
  setError: (value?: DatafeedError) => void;
}

export const datafeedState: State = {
  channel: {},
  error: undefined,
};

export const datafeedActions: StateCreator<State & Actions, [], [], Actions> = (
  set,
) => ({
  setChannel: (value) => {
    set((state) =>
      produce(state, (draft) => {
        draft.channel = {
          ...draft.channel,
          ...value,
        };
      }),
    );
  },
  setError: (value) => {
    set((state) =>
      produce(state, (draft) => {
        draft.error = value;
      }),
    );
  },
});

export type DatafeedStore = Actions & State;

export const useDatafeedStore = create<DatafeedStore>(
  (set, get, store, storeMutation) => ({
    ...datafeedState,
    ...datafeedActions(set, get, store, storeMutation),
  }),
);
