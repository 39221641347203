import { MarketMoverWebsocketRequest } from '@stockbitgroup/protos/financial/order_trade/entity/v1/market_mover_pb';
import produce from 'immer';
import create from 'zustand';

type MarketMoverState = {
  request: {
    [key: string]: MarketMoverWebsocketRequest
  };
}

type MarketMoverActions = {
  setMoverRequest: (key: string, value?: MarketMoverWebsocketRequest) => void;
}

export type MarketMoverStore = MarketMoverState & MarketMoverActions;

export const useMarketMoverStore = create<MarketMoverStore>((set) => ({
  request: {},
  setMoverRequest: (key, value) => {
    set((state) =>
      produce(state, (draft) => {
        if (!value) delete draft.request[key];
        else draft.request[key] = value;
      }),
    );
  },
}));
