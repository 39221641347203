import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

import * as livestreamApi from 'lib/api/livestream';
import { CommonPayload } from '../../../@types/action-payload';

/**
 * Note for event filtered data Schema
 * because we pass status as argument, we will use that as key to save the response
 * the schema will look like this
 *
 * data[eventStatus]: [
 *   { ... }
 * ]
 */

const initialState = {
  eventList: {
    data: [],
    isLoading: false,
    message: '',
    error: '',
  },
  eventFiltered: {
    data: {},
    isLoading: false,
    isLoadingMore: false,
    hasMore: false,
    message: '',
    error: '',
  },
  event: {
    data: null,
    isLoading: false,
    message: '',
    error: '',
  },
  writeQuestion: {
    data: null,
    isLoading: false,
    message: '',
    error: '',
  },
};

type LiveStreamState = typeof initialState;

const liveStream = (state) => state.liveStream;
export const selectors = createSelector(
  liveStream,
  (liveStreamState: LiveStreamState) => ({
    events: liveStreamState.eventList,
    event: liveStreamState.event,
    eventFiltered: liveStreamState.eventFiltered,
    writeQuestions: liveStreamState.writeQuestion,
  }),
);

const CONTEXT = '@feature/livestream';

const actionType = {
  GET_EVENTS: `${CONTEXT}/GET_EVENTS`,
  GET_EVENTS_FILTERED: `${CONTEXT}/GET_EVENTS_FILTERED`,
  GET_EVENT: `${CONTEXT}/GET_EVENT`,
  WRITE_QUESTION: `${CONTEXT}/WRITE_QUESTION`,
};

interface WriteQuestionParams {
  id: string | number;
  question: string;
}

interface GetEventListParams {
  page?: number;
  limit?: number;
  status: 'EVENT_STATUS_UPCOMING' | 'EVENT_STATUS_LIVE' | 'EVENT_STATUS_ENDED';
}

export const effects = {
  getEventList: createAsyncThunk(
    actionType.GET_EVENTS,
    // @ts-ignore
    async () => {
      try {
        const response = await livestreamApi.getEventList({
          page: 1,
          limit: 20,
        });

        if (!response.data) {
          throw new Error('Failed to get event list');
        }

        const { data, message } = response.data;

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getEvent: createAsyncThunk<CommonPayload, string | string[]>(
    actionType.GET_EVENT,
    async (props) => {
      try {
        const response = await livestreamApi.getEvent(props);

        if (!response.data) {
          throw new Error('Failed to get event list');
        }

        const { data, message } = response.data;

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getEventListFiltered: createAsyncThunk(
    actionType.GET_EVENTS_FILTERED,
    // @ts-ignore
    async (props: GetEventListParams) => {
      try {
        const { page = 1, limit = 16, status } = props;
        const response = await livestreamApi.getEventList({
          page,
          limit,
          status,
        });

        if (!response.data) {
          throw new Error('Failed to get event list');
        }

        const { data, message } = response.data;

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  writeQuestion: createAsyncThunk<CommonPayload, WriteQuestionParams>(
    actionType.WRITE_QUESTION,
    async (props) => {
      try {
        const response = await livestreamApi.writeQuestion(props);

        if (!response.data) {
          throw new Error('Failed to get event list');
        }

        const { data, message } = response.data;

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getEventList.pending, (state: LiveStreamState) => {
      state.eventList.isLoading = true;
      state.eventList.error = null;
    })
    .addCase(
      effects.getEventList.fulfilled,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.eventList.error = error;
        } else {
          state.eventList.data = data;
        }

        state.eventList.message = message;
        state.eventList.isLoading = false;
      },
    )
    .addCase(
      effects.getEventList.rejected,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        state.eventList.isLoading = false;
        state.eventList.error = action.payload.error;
      },
    )
    .addCase(effects.getEvent.pending, (state: LiveStreamState) => {
      state.event.isLoading = true;
      state.event.error = null;
    })
    .addCase(
      effects.getEvent.fulfilled,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.event.error = error;
        } else {
          state.event.data = data;
        }

        state.event.message = message;
        state.event.isLoading = false;
      },
    )
    .addCase(
      effects.getEvent.rejected,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        state.event.isLoading = false;
        state.event.error = action.payload.error;
      },
    )
    .addCase(effects.writeQuestion.pending, (state: LiveStreamState) => {
      state.writeQuestion.isLoading = true;
      state.writeQuestion.error = null;
    })
    .addCase(
      effects.writeQuestion.fulfilled,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        const { error, data, message } = action.payload;

        if (error) {
          state.writeQuestion.error = error;
        } else {
          state.writeQuestion.data = data;
        }

        state.writeQuestion.message = message;
        state.writeQuestion.isLoading = false;
      },
    )
    .addCase(
      effects.writeQuestion.rejected,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        state.writeQuestion.isLoading = false;
        state.writeQuestion.error = action.payload.error;
      },
    )
    .addCase(
      effects.getEventListFiltered.pending,
      (
        state: LiveStreamState,
        action: PayloadAction<CommonPayload, any, { arg: GetEventListParams }>,
      ) => {
        const { page } = action.meta.arg;

        state.eventFiltered.error = null;

        if (page > 1) {
          state.eventFiltered.isLoadingMore = true;
        } else {
          state.eventFiltered.isLoading = true;
        }
      },
    )
    .addCase(
      effects.getEventListFiltered.fulfilled,
      (
        state: LiveStreamState,
        action: PayloadAction<CommonPayload, any, { arg: GetEventListParams }>,
      ) => {
        const { error, data, message } = action.payload;
        const { page, status } = action.meta.arg;

        if (error) {
          state.eventFiltered.error = error;
        } else {
          if (page > 1) {
            state.eventFiltered.data = {
              [status]: [...state.eventFiltered.data[status], ...data],
            };
            state.eventFiltered.isLoadingMore = false;
          } else {
            state.eventFiltered.data = {
              ...state.eventFiltered.data,
              [status]: data,
            };
            state.eventFiltered.isLoading = false;
          }

          if (data.length === 16) {
            state.eventFiltered.hasMore = true;
          } else {
            state.eventFiltered.hasMore = false;
          }
        }

        state.eventFiltered.message = message;
      },
    )
    .addCase(
      effects.getEventListFiltered.rejected,
      (state: LiveStreamState, action: PayloadAction<CommonPayload>) => {
        state.eventFiltered.isLoading = false;
        state.eventFiltered.error = action.payload.error;
      },
    );
};

const liveStreamSlice = createSlice({
  name: 'livestream',
  initialState,
  reducers: {},
  extraReducers,
});

export default liveStreamSlice;
