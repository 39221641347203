/* eslint-disable import/named */
/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */
import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const request = requestConfig();

const LIVE_STREAM_URL = `${API_EXODUS_URL}/live-stream`;

/**
 * Get Livestreams Event List
 * @param { Object } params
 * @param {number=} params.page
 * @param {string=} params.limit
 * @param {string=} params.status
 * @returns {APIResponse}
 */
export const getEventList = (params): Promise<APIResponse> =>
  request.get(`${LIVE_STREAM_URL}/event`, { params });

/**
 * Get Livestream Event
 * @param {string|number=} id
 * @returns {APIResponse}
 */
export const getEvent = (id): Promise<APIResponse> =>
  request.get(`${LIVE_STREAM_URL}/event/${id}`);

/**
 * Write Question
 * @param { Object } params
 * @param {number=} params.id
 * @param {string=} params.question
 * @returns {APIResponse}
 */
export const writeQuestion = (params): Promise<APIResponse> =>
  request.post(`${LIVE_STREAM_URL}/event/${params.id}/question`, { question: params.question });

/**
 * Get Nont Login Livestream Event
 * @param {string|number=} id
 * @returns {APIResponse}
 */
export const getEventNonLogin = (id): Promise<APIResponse> =>
  request.get(`${LIVE_STREAM_URL}/non-login/event/${id}`);

export default {
  getEventList,
  getEvent,
  writeQuestion,
  getEventNonLogin,
};
