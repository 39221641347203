import produce from 'immer';
import create, { StateCreator } from 'zustand';
import type {
  OrderbookActions,
  OrderbookState,
  OrderbookStore,
} from './orderbook.store.types';

const orderbookState: OrderbookState = {
  symbols: {},
};

const orderbookActions: StateCreator<OrderbookStore, [], [], OrderbookActions> =
  (set) => ({
    setOrderbookSymbols: (key, symbols) => {
      set((state) =>
        produce(state, (draft) => {
          draft.symbols[key] = symbols.filter((v, i, a) => a.indexOf(v) === i);
        }),
      );
    },
  });

export const useOrderbookStore = create<OrderbookStore>(
  (set, get, store, storeMutation) => ({
    ...orderbookState,
    ...orderbookActions(set, get, store, storeMutation),
  }),
);
