import { DatePicker } from "antd";
import styled from "styled-components";
import color from "../_style/color";

export default styled(DatePicker)`
  font-family: "ProximaNovaRg";
  border: 1px solid ${color.gainsboro} !important;
  border-color: ${color.gainsboro} !important;
  box-shadow: none !important;

  :hover,
  :focus {
    border: 1px solid ${color.gainsboro} !important;
    border-color: ${color.gainsboro} !important;
    box-shadow: none !important;
  }
`;
