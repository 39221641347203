import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  getCalendarFor,
  getCalendarTodaySummary,
} from 'lib/api/calendar';

import { ICalendarToday, ICalendarTabs } from './type';

const CONTEXT = '@feature/calendar';

const actionType = {
  GET_CALENDAR_TODAY: `${CONTEXT}/GET_CALENDAR_TODAY`,
  GET_CALENDAR_ECONOMIC: `${CONTEXT}/GET_CALENDAR_ECONOMIC`,
  GET_CALENDAR_CASH_DIVIDEND: `${CONTEXT}/GET_CALENDAR_CASH_DIVIDEND`,
  GET_CALENDAR_STOCK_DIVIDEND: `${CONTEXT}/GET_CALENDAR_STOCK_DIVIDEND`,
  GET_CALENDAR_STOCKSPLIT: `${CONTEXT}/GET_CALENDAR_STOCKSPLIT`,
  GET_CALENDAR_REVERSESPLIT: `${CONTEXT}/GET_CALENDAR_REVERSESPLIT`,
  GET_CALENDAR_RIGHTISSUE: `${CONTEXT}/GET_CALENDAR_RIGHTISSUE`,
  GET_CALENDAR_WARRANT: `${CONTEXT}/GET_CALENDAR_WARRANT`,
  GET_CALENDAR_BONUS: `${CONTEXT}/GET_CALENDAR_BONUS`,
  GET_CALENDAR_TENDER: `${CONTEXT}/GET_CALENDAR_TENDER`,
  GET_CALENDAR_RUPS: `${CONTEXT}/GET_CALENDAR_RUPS`,
  GET_CALENDAR_PUBEX: `${CONTEXT}/GET_CALENDAR_PUBEX`,
  GET_CALENDAR_IPO: `${CONTEXT}/GET_CALENDAR_IPO`,
};

interface ICalendarState {
  calendarToday: ICalendarToday;
  calendarEconomic: ICalendarTabs;
  calendarCashDividend: ICalendarTabs;
  calendarStockDividend: ICalendarTabs;
  calendarStocksplit: ICalendarTabs;
  calendarReversesplit: ICalendarTabs;
  calendarRightIssue: ICalendarTabs;
  calendarWarrant: ICalendarTabs;
  calendarBonus: ICalendarTabs;
  calendarTender: ICalendarTabs;
  calendarRups: ICalendarTabs;
  calendarPubex: ICalendarTabs;
  calendarIpo: ICalendarTabs;
}

const initialState: ICalendarState = {
  calendarToday: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarEconomic: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarCashDividend: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarStockDividend: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarStocksplit: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarReversesplit: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarRightIssue: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarWarrant: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarBonus: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarTender: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarRups: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarPubex: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
  calendarIpo: {
    data: undefined,
    error: undefined,
    isLoading: undefined,
    message: undefined,
  },
};

type TCalendarState = typeof initialState;

const calendarState = (state) => state.calendar;
export const selectors = createSelector(
  calendarState,
  (calendar: TCalendarState) => ({
    calendarToday: calendar.calendarToday,
    calendarEconomic: calendar.calendarEconomic,
    calendarCashDividend: calendar.calendarCashDividend,
    calendarStockDividend: calendar.calendarStockDividend,
    calendarStocksplit: calendar.calendarStocksplit,
    calendarReversesplit: calendar.calendarReversesplit,
    calendarRightIssue: calendar.calendarRightIssue,
    calendarWarrant: calendar.calendarWarrant,
    calendarBonus: calendar.calendarBonus,
    calendarTender: calendar.calendarTender,
    calendarRups: calendar.calendarRups,
    calendarPubex: calendar.calendarPubex,
    calendarIpo: calendar.calendarIpo,
  }),
);

/**
 * Get Calendar Data based on Type
 * @summary - available calendar type: economic | dividened | stocksplit
 * | reversesplit | reversesplit | rightissue | warrant | bonus | tenderoffer | rups | pubex | ipo
 * @param {string} calendarTypes - type of calendar
 */
export const effects = {
  getCalendarToday: createAsyncThunk<any>(
    actionType.GET_CALENDAR_TODAY,
    async () => {
      try {
        const responds = await getCalendarTodaySummary();
        if (!responds.data) {
          throw new Error('Attempt get today calendar is failed');
        }
        const { data } = responds;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarEconomic: createAsyncThunk<any>(
    actionType.GET_CALENDAR_ECONOMIC,
    async () => {
      try {
        const responds = await getCalendarFor('economic');

        if (!responds.data) {
          throw new Error('Attempt to get calender for economic failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarCashDividend: createAsyncThunk<any>(
    actionType.GET_CALENDAR_CASH_DIVIDEND,
    async () => {
      try {
        const responds = await getCalendarFor('dividend');

        if (!responds.data) {
          throw new Error('Attempt to get calender for dividend failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarStockDividend: createAsyncThunk<any>(
    actionType.GET_CALENDAR_STOCK_DIVIDEND,
    async () => {
      try {
        const responds = await getCalendarFor('stock_dividend');

        if (!responds.data) {
          throw new Error('Attempt to get calender for stock dividend failed');
        }

        const { data } = responds;

        console.log('data', data);
        console.log('responds', responds);

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarStocksplit: createAsyncThunk<any>(
    actionType.GET_CALENDAR_STOCKSPLIT,
    async () => {
      try {
        const responds = await getCalendarFor('stocksplit');

        if (!responds.data) {
          throw new Error('Attempt to get calender for stocksplit failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarReversesplit: createAsyncThunk<any>(
    actionType.GET_CALENDAR_REVERSESPLIT,
    async () => {
      try {
        const responds = await getCalendarFor('reversesplit');

        if (!responds.data) {
          throw new Error('Attempt to get calender for reverse split failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarRightIssue: createAsyncThunk<any>(
    actionType.GET_CALENDAR_RIGHTISSUE,
    async () => {
      try {
        const responds = await getCalendarFor('rightissue');

        if (!responds.data) {
          throw new Error('Attempt to get calender for right issue failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarWarrant: createAsyncThunk<any>(
    actionType.GET_CALENDAR_WARRANT,
    async () => {
      try {
        const responds = await getCalendarFor('warrant');

        if (!responds.data) {
          throw new Error('Attempt to get calender for warrant failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarBonus: createAsyncThunk<any>(
    actionType.GET_CALENDAR_BONUS,
    async () => {
      try {
        const responds = await getCalendarFor('bonus');

        if (!responds.data) {
          throw new Error('Attempt to get calender for bonus failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarTender: createAsyncThunk<any>(
    actionType.GET_CALENDAR_TENDER,
    async () => {
      try {
        const responds = await getCalendarFor('tenderoffer');

        if (!responds.data) {
          throw new Error('Attempt to get calender for tender offer failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarRups: createAsyncThunk<any>(
    actionType.GET_CALENDAR_RUPS,
    async () => {
      try {
        const responds = await getCalendarFor('rups');

        if (!responds.data) {
          throw new Error('Attempt to get calender for RUPS failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarPubex: createAsyncThunk<any>(
    actionType.GET_CALENDAR_PUBEX,
    async () => {
      try {
        const responds = await getCalendarFor('pubex');

        if (!responds.data) {
          throw new Error('Attempt to get calender for PUBEX failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCalendarIpo: createAsyncThunk<any>(
    actionType.GET_CALENDAR_IPO,
    async () => {
      try {
        const responds = await getCalendarFor('ipo');

        if (!responds.data) {
          throw new Error('Attempt to get calender for IPO failed');
        }

        const { data } = responds;

        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
};

const extraReducer = (builder) => {
  builder
    .addCase(
      effects.getCalendarToday.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarToday>) => {
        const { message, data } = action.payload;

        state.calendarToday.isLoading = false;
        state.calendarToday.data = data;
        state.calendarToday.message = message;
      },
    )
    .addCase(
      effects.getCalendarToday.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarToday>) => {
        state.calendarToday.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarToday.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarToday>) => {
        state.calendarToday.isLoading = false;
        state.calendarToday.error = action.payload.error;
      },
    )
    .addCase(
      effects.getCalendarEconomic.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarEconomic.data = data;
        state.calendarEconomic.message = message;
        state.calendarEconomic.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarEconomic.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarEconomic.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarEconomic.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarEconomic.error = action.payload.error;
        state.calendarEconomic.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarCashDividend.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarCashDividend.data = data;
        state.calendarCashDividend.message = message;
        state.calendarCashDividend.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarCashDividend.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarCashDividend.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarCashDividend.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarCashDividend.error = action.payload.error;
        state.calendarCashDividend.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarStockDividend.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarStockDividend.data = data;
        state.calendarStockDividend.message = message;
        state.calendarStockDividend.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarStockDividend.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarStockDividend.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarStockDividend.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarStockDividend.error = action.payload.error;
        state.calendarStockDividend.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarStocksplit.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarStocksplit.data = data;
        state.calendarStocksplit.message = message;
        state.calendarStocksplit.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarStocksplit.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarStocksplit.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarStocksplit.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarStocksplit.error = action.payload.error;
        state.calendarStocksplit.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarReversesplit.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarReversesplit.data = data;
        state.calendarReversesplit.message = message;
        state.calendarReversesplit.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarReversesplit.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarReversesplit.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarReversesplit.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarReversesplit.error = action.payload.error;
        state.calendarReversesplit.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarRightIssue.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarRightIssue.data = data;
        state.calendarRightIssue.message = message;
        state.calendarRightIssue.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarRightIssue.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarRightIssue.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarRightIssue.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarRightIssue.error = action.payload.error;
        state.calendarRightIssue.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarWarrant.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarWarrant.data = data;
        state.calendarWarrant.message = message;
        state.calendarWarrant.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarWarrant.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarWarrant.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarWarrant.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarWarrant.error = action.payload.error;
        state.calendarWarrant.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarBonus.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarBonus.data = data;
        state.calendarBonus.message = message;
        state.calendarBonus.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarBonus.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarBonus.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarBonus.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarBonus.error = action.payload.error;
        state.calendarBonus.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarTender.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarTender.data = data;
        state.calendarTender.message = message;
        state.calendarTender.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarTender.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarTender.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarTender.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarTender.error = action.payload.error;
        state.calendarTender.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarRups.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarRups.data = data;
        state.calendarRups.message = message;
        state.calendarRups.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarRups.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarRups.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarRups.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarRups.error = action.payload.error;
        state.calendarRups.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarPubex.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarPubex.data = data;
        state.calendarPubex.message = message;
        state.calendarPubex.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarPubex.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarPubex.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarPubex.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarPubex.error = action.payload.error;
        state.calendarPubex.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarIpo.fulfilled,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        const { message, data } = action.payload;

        state.calendarIpo.data = data;
        state.calendarIpo.message = message;
        state.calendarIpo.isLoading = false;
      },
    )
    .addCase(
      effects.getCalendarIpo.pending,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarIpo.isLoading = true;
      },
    )
    .addCase(
      effects.getCalendarIpo.rejected,
      (state: TCalendarState, action: PayloadAction<ICalendarTabs>) => {
        state.calendarIpo.error = action.payload.error;
        state.calendarIpo.isLoading = false;
      },
    );
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  // for non-async reducer logic
  reducers: {},
  // for async reducer logic
  extraReducers: extraReducer,
});

export default calendarSlice;
