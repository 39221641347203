import { TrendingTimeFrame } from 'features/discover-people/types';
import { TypeFilter } from './types';

const USER_TYPES = {
  OFFICIAL_USER: 'OFFICIAL',
  TOP: 'TOP',
};

const SUGGESTIONS_PERPAGE = 30;
const LIMIT_SEARCH_USER = 20;

const QUERY_KEY_DATA = {
  suggested: 'getDataFriendSuggestionsListTrendingWidget',
  trending: 'getDataTrendingList',
};

// fetch statuses
const FETCH_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

const FILTER_CHOICE = [
  { label: 'Weekly', value: TrendingTimeFrame.ONE_WEEK },
  { label: 'Monthly', value: TrendingTimeFrame.ONE_MONTH },
  { label: 'All-time', value: TrendingTimeFrame.ALL },
];

const LIST_CHOICE = {
  SUGGESTED: <TypeFilter>'suggested',
  TRENDING: <TypeFilter>'trending',
};

export const INDEX_DATA_FILTER = {
  SUGGESTED: 'useSuggestionList',
  TRENDING: 'useTrendingList',
};

export {
  SUGGESTIONS_PERPAGE,
  LIMIT_SEARCH_USER,
  USER_TYPES,
  QUERY_KEY_DATA,
  FETCH_STATUS,
  FILTER_CHOICE,
  LIST_CHOICE,
};
